﻿@import 'variable.less';
@import 'mixin.less';

.range-slider {
  -webkit-appearance: none !important;
  width: 100%;
  height: 20px;
  background: transparent !important;
  background-image: linear-gradient(90deg, @baseColor var(--percentage), transparent var(--percentage));
  border: none !important;
  
    &:hover {
        opacity: 1 !important;
    }

    &:focus {
        outline: none !important;
    }

    &::-webkit-slider-runnable-track {
        width: 100%;
        height: 4px;
        cursor: pointer;
        background: #B8CCDA !important;
        background-image: linear-gradient(90deg, @baseColor var(--percentage), transparent var(--percentage)) !important;
        -webkit-transition: .2s !important;
        transition: opacity .2s !important;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        height: 20px;
        width: 20px;
        cursor: pointer;
        background: @baseColor !important;
        border-radius: 50% !important;
        margin-top: -8px;
    }
}

//ref: https://stackoverflow.com/a/62983699/25284261
.range-list {
    display: flex;
    height: auto;
    overflow: hidden;
    margin-top: -4px;
    color: #B9B9B9;

    .range-list-opt {
        display: inline-block;
        width: calc((100% - var(--thumb-width)) / (var(--list-length) - 1));
        text-align: center;
    }
  }

.range-wrap {
    width: 100%;
    position: relative;
    color: @baseColor;
}

.range-value {
    position: absolute;
    top: -50%;

    span {
        width: 30px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        font-size: 12px;
        display: block;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);

        &:before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            margin-top: -1px;
        }
    }
}
.segment-control {
      overflow: scroll;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */

        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
        }

    .btn-segment {
        background-color: white !important;
        color: black !important;
        padding: 8px 15px;
        font-size: 12px;
        font-weight: 500;
        border-width: 1px;
        border-color: #DDDDDD white #DDDDDD white;
        border-style: solid none solid none;
        border-radius: 0 !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        text-transform: none;

        &:first-child {
            border-top-left-radius: 0.25rem !important;
            border-bottom-left-radius: 0.25rem !important;
            border-style: solid none solid solid;
            border-color: #DDDDDD white #DDDDDD #DDDDDD;
        }

        &:last-child {
            border-top-right-radius: 0.25rem !important;
            border-bottom-right-radius: 0.25rem !important;
            border-style: solid solid solid none;
            border-color: #DDDDDD #DDDDDD #DDDDDD white;
        }

        &:hover, &.active {
            background-color: #F4FAFF !important;
            border-color: @baseColor;
            border-width: 1px;
            border-style: solid;
            border-radius: 0.25rem !important;
            color: black !important;
        }
    }
}
.segment-left
{
     position: absolute;
     display: flex;
     align-items: center;
     justify-content: center;
     width: 10px;
     height: 35px;
     left: 20px;
}
.segment-right {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 35px;
    right: 20px;
}