﻿@import '../mixin.less';
@import '../variable.less';

@media all and (max-width: 1072px) {
    .navbar {
        .navbar-nav {
            display: table;
            float: none;
            vertical-align: middle;
            width: 720px;
            margin: 0 auto;
        }

        .navbar-collapse {
            #social-icon {
                position: absolute;
                right: 10px;
                bottom: -30px;
                z-index: 999;
            }
        }
    }

    #wrapper {
        .navbar-nav.menu.menu-main-menu {
            margin: 10.25px auto;
            display: block;
            padding: 0 20px;
            width: 80%!important;

            & > li {
                display: block;
                float: none;
                margin-bottom: 25px;

                &:hover {
                    position: relative;
                }

                & > a {
                    margin-bottom: 5px;
                    padding: 0 0 5px;
                    display: block;
                }
            }
        }
    }

    .zone.zone-navigation {
        position: relative;
        width: 100%;

        .widget-navigation.widget-layout-widget.widget {
            .orchard-layouts-root {

                & > div {
                    width: 100%;
                    min-height: 70px;
                    height: 100%;
                    position: absolute;
                }
            }
        }
    }
}

@media all and (max-width: 768px) {
    .navbar {
        min-height: 0;

        .navbar-collapse {
            text-align: left;
            width: 100%;
        }
    }
    .nav-wrapper {
        .navbar-toggle {
            position: absolute!important;
            right: 0;
            top: 0;
            z-index: 999;
            background: #fff;
            opacity: .7;
            display: block;

            &:hover {
                opacity: 1;
            }
        }
    }

    .navbar-toggle.collapsed {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
        background: #fff;
        opacity: .7;

        &:hover {
            opacity: 1;
        }
    }

    .navbar-collapse {
        padding: 0;
    }

}