﻿@import '../mixin.less';
@import '../variable.less';

@media all and (max-width: 768px) {
    #map-container {
        height: 100%;
        overflow: hidden;

        #listing {
            float: none;
            width: 100%;
            height: 300px;
            
        }

        #map {
            height: 100%;
            position: relative;
            width: 100%;
            height: 300px;
        }
    }

    .zone.zone-content {
        .filter-panel.single-selection {
            position: relative;
            top: 10px; 
            z-index: 99;
            padding: 0 0 0 5px;
        }

        .filter-wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            margin-bottom: 20px;

            .filter {
                #button-slide {
                    position: absolute;
                    right: -25px;
                    top: 0;
                    background: #4b3e75;
                    color: @baseLightFontColor;
                    padding: 5px;

                    &:before {
                        .icon-font('\e250');
                        position: relative;
                        top: 1px;
                        left: 0;
                        top: 0;
                    }
                }
            }
        }

        .map-listing {
            .map-wrapper {
                height: 426px;
                position: relative;
                margin-bottom: 20px;
                top: 30px;
            }
        }

        .result-listing {
            float: none;
            width: 100%;

            ul {

                li {
                    width: 100%;
                }
            }
        }
    }
    #home-list {
        .result-listing {
            ul {
                li.home-list {
                    height: 100%;
                    position: relative;

                    div.img-house {
                        float: none;
                        width: 100%;
                        margin-bottom: 15px;
                    }

                    div.content-left {
                        float: none;
                        width: 100%;
                        position: static;

                        .home-detail-first {
                            margin: 0;

                            .home-price {
                                position: static;
                                text-align: right;
                                margin: 15px 0;
                            }
                        }

                        div.home-option {
                            position: static;
                        }
                    }
                }
            }
        }
    }
}