﻿@import "variable.less";
@import "mixin.less";

li.premium-member {
    .premium-icon-listing(0);
}

.projection-page.content-item {
    ul.projector-list-layout.projector-layout {
        list-style: none;
        padding: 20px;
        background: #f3f3f3;

        li {
            border-bottom: 1px solid #757575;
            margin-bottom: 15px;
            padding: 15px;

            &:last-child {
                border: 0;
                margin-bottom: 0;
                padding-bottom: 0;
            }

            h3 {
                font-size: 22px;
                margin: 0 0 5px;

                a {
                    font-weight: bold;
                    text-decoration: underline;
                    color: @color-4ec9f4;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
              
            p {
                margin-bottom: 0;
            }

            p.price {
                font-weight: bold;
                font-size: 22px;
                color: @colorProjection;
                position: relative;

                &:before {
                    content: "$";
                    color: @color-333434;
                    font-size: 22px;
                    font-weight: bold;
                }
            }

            p.bedrooms {
                .text-price(" BD");
            }

            p.bathrooms {
                .text-price(" BA");

                &:before {
                    content: ":";
                    padding-right: 5px;
                }
            }

            p.size-home {
                .text-price(" sq.ft.");
                &:before {
                    content: ":";
                    padding-right: 5px;
                }
            }
            p.zip-postal-code,
            p.address-city  {
                display: inline-block;
            }

            p.address-city {
                margin-right: 5px;
            }
        }
    }
}


.projection-page.content-item {
    .list-group.builder-list {

        li.list-group-item {
            border: none;
            border-bottom: 2px solid @color-ddd;
        }

        &:last-child {
            border-bottom: none;
        }

        .non-premium {
            .website,
            .company-logo,
            .email,
            .social-list-builder,
            .address  {
                display: @displayNone;
            }
        }
        // move this code to mixin 
        .premium-plus {
            .premium-icon-listing(-50%);
        }

        // move this code to mixin 
        .premium {
            .premium-icon-listing(-50%);
            .email,
            .social-list-builder,
            .company-logo,
            .address {
                display: @displayNone;
            }
        }
    }
}

#pagination {
    float: none;
    margin-top: 15px;

    ul.pager {
        margin: 0;
        overflow: hidden;
        margin-left: -10px;
        display: block;
        border-radius: 0; 
        list-style: none;
        padding: 0;

        li {
            float: left;
            margin-left: 10px;

            &:first-child {
                a {
                    border-radius: 0;
                }
            }

            &:last-child {
                a {
                    border-radius: 0;
                }
            }

            a {
                display: block;
                border: none;
                padding: 0;
                background: none;
                color: @color-497ec4;
                border: 1px solid @color-2E4287;
                padding: 2px 8px;
                background: @color-2E4287;
                color: @baseLightFontColor;
                border-radius: 0;

                &:hover {
                    opacity: .7;
                }
            }

            a#pager-current {
                color: @baseLightFontColor;
            }
        }

        li.active {
            a {
                opacity: .7;

                &:hover {
                    opacity: .7;
                }
            }
        }
    }
}

.contentMember.listing {
    margin: 0;
}

.addressWrap {
    .phone {
        position: relative;
        padding-left: 20px;
        word-break: break-all;
        margin: 0;

        &:before {
            content: "\f095";
            font-family: 'FontAwesome';
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            position: absolute;
            left: 0;
            top: 6px;
            color: #000;
        }
    }

    .fax-number {
        position: relative;
        padding-left: 20px;
        word-break: break-all;
        margin: 0 0 5px;

        &:before {
            content: "";
            position: absolute;
            left: -3px;
            top: 3px;
            background: url(../Content/images/fax-icon.jpg) no-repeat left top;
            background-size: 100% 100%;
            width: 17px;
            height: 17px;
        }
    }
}

h5.companyName {
    color: #2196f3;
    font-weight: bold;
    font-size: 20px;
    margin: 0;
    line-height: 1;
}

/*hide Address Fieds for non Premium*/
.contentMemberlisting {
    .content-member-listing-wrapper {
        .addressWrap {
            display: none;
        }
    }

    .content-member-listing-wrapper.premium {
        .addressWrap {
            display: block;
        }
    }
}

.content-member-listing-wrapper {
    & > .contentInnerMember {
        padding: 15px 0;

        .inner {
            margin: 0 -15px;
        }
    }
}

//For Featured Tag
.featured-home {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px 10px;
}