﻿@import "variable.less";
@import "mixin.less";


.carousel-home {
    margin-bottom: 33px;

    .carousel-inner {
        max-width: 1140px;
        height: auto;

        img {
            max-height: 100%;
            width: 100%;
        }
    }

    .carousel-indicators {
        position: absolute;
        bottom: -33px;
        z-index: 15;
        left: 0;
        width: 100%;
        padding-left: 0;
        list-style: none;
        text-align: center;
        margin: 0;
        background: @baseColor;    
        padding: 5px 0;
    }
}

/*Slider min & max style*/
.slider.slider-horizontal {
    margin-top: 30px;
    width: 100%;

    .slider-track {
        background: #4A4A4A;
        height: 5px;
    }

    .slider-selection {
        background: #4A4A4A;
    }
}
    
.slider-handle {
    background: @baseColor;
    width: 20px;
    height: 20px;
    border: 3px solid #fff;
    top: -4px;
}

.slider {

    .tooltip-inner {
        background: @baseColor;
        color: @baseLightFontColor;
        font-weight: bold;
    }

    .tooltip.top {

        .tooltip-arrow {
            border-top-color: @baseColor;
        }
    }
}
    
.sponsor-slide {
    margin: 20px 0;

    .carousel-indicators.hidden-xs,
    .left.carousel-control.visible-xs,
    .right.carousel-control.visible-xs,
    .cloneditem-1,
    .cloneditem-2,
    .cloneditem-3 {
        display: @displayNone;
    }

    .carousel-control {
        width: 4%;
        background-image: none;
    }

    .carousel-control.left {
        margin-left: 0;
    }

    .carousel-control.right {
        margin-right: 0;
    }

    .active.carousel-item-left {
        opacity: 0;
        transition: .5s;
    }
    .carousel-item-next.carousel-item-left {
        opacity: 1;
    }

    .carousel-inner {
        height: auto;

        .carousel-item {
            overflow: hidden;

            img {
                width: 16.66666667%;
                display: inline-block;
                float: left;
                padding: 0 15px;
            }
        }
    }
}

.carousel {
    .item {

        .col-xs-12 {
            padding: 0;
        }
    }
}

#carousel-HomePhotos {

    .slick-prev {
        z-index: 999;
        opacity: 0.5;

        &:hover {
            opacity: 1;
        }

        &:before {
            color: #eee;
            content: "";
            display: block;
            width: 25px;
            height: 25px;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23333' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
        }
    }

    .slick-next {
        z-index: 999;
        opacity: 0.5;

        &:hover {
            opacity: 1;
        }

        &:before {
            color: #eee;
            content: "";
            display: block;
            width: 25px;
            height: 25px;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23333' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
        }
    }

    .slick-slider.slider-for {
        height: 460px;

        img {
            height: 450px;
            display: block;
            margin: 0 auto;
            text-align: center;
        }

        .slick-slide {
            background: #eee;
        }
    }

    .slider-nav.slick-slider {
        margin-top: 60px;

        .slick-slide {
            margin: 0 10px;
            height: 100px !important;

            img {
                width: 128px;
                height: 100px;
                text-indent: 0px;
                float: left;
                margin: 10px 10px 10px 0px;
                border: none;
                opacity: 0.3;
            }
        }

        .slick-slide.slick-active.slick-center {

            img {
                border: 1px solid #eee;
                opacity: 1;
            }
        }

        .slick-list.draggable {
            padding: 0 0 10px 0 !important;
        }
    }
}
    
#accordion{
    color: @baseLightFontColor;

    .panel {
        box-shadow: none;
        margin-bottom: 15px;

        .list-group-item {
            padding: 10px 30px 10px 15px;
            border: none;
            background: @baseColor;
            color: @baseLightFontColor;
            border-radius: 0;
            font-size: 20px;
            font-weight: bold;

            span.badge {
                background: none;
                font-size: 20px;
                font-weight: bold;
                padding: 0;
                position: relative;

                &:after {
                    .icon-font('\e259');
                }
            }
        }

        .list-group-item.collapsed {
            span.badge {

                &:after {
                    .icon-font('\f054');
                }
            }
        }

        .panel-body {
            padding: 15px 0;

            ul.products-list {
                li.list-group-item {
                    background: none;
                    border-bottom: 3px solid @baseColor;
                    margin: 0 0 15px;
                    padding: 0 0 15px 0;
                    color: #666;
                    font-size: 16px;
                    font-weight: normal;

                    a {
                        overflow: hidden;
                        display: block;
                        color: #666;

                        h4 {
                            font-family: @fontFamilyTitle;
                            font-weight: bold;
                            font-style: initial;
                        }

                        h5 {
                            font-family: @fontFamilyTitle;
                            margin: 0;
                        }

                        &:hover {
                            h5 {
                                text-decoration: underline;
                            }
                        }

                        img.img-responsize {
                            width:50%;
                            max-height: 100%;
                        }

                        .full-name,
                        .phone-num {
                            font-size: 16px;
                        }
                    }
                }

                .non-premium {
                    .websitem,
                    .company-logo,
                    .email,
                    .social-list-builder,
                    .address {
                        display: @displayNone;
                    }
                }

                .premium-plus {
                    padding-right: 50px;

                    &:before {
                        .icon-font-premium();
                    }
                }

                .premium {
                    padding-right: 50px;

                    &:before {
                        .icon-font-premium();
                    }

                    .company-logo,
                    .email,
                    .social-list-builder,
                    .address {
                        display: @displayNone;
                    }
                }
            }
        }
    }
}

/* Carousel lightbox */

.ekko-lightbox-nav-overlay {
    a {
        opacity: 1;

        &:first-child {
            span {
                position: relative;
                left: -90px;
            }
        }

        &:last-child {
            span {
                position: relative;
                right: -90px;
            }
        }
    }
}