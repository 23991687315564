﻿@import '../mixin.less';
@import '../variable.less';

@media all and (max-width: 768px) {
    #carousel-HomePhotos {
        .slick-slider.slider-for {
            height: auto;

            img {
                height: auto;
            }
        }

        .slider-nav.slick-slider {
            margin-top: 0px;

            .slick-slide {
                margin: 0 10px;
                height: 100px !important;

                img {
                    width: 128px;
                    height: 100px;
                    text-indent: 0px;
                    float: left;
                    margin: 10px 10px 10px 0px;
                    border: none;
                    opacity: 0.3;
                }
            }

            .slick-slide.slick-active.slick-center {

                img {
                    border: 1px solid #eee;
                    opacity: 1;
                }
            }

            .slick-list.draggable {
                padding: 0 0 10px 0 !important;
            }
        }
    }

    .text-column {
        .text-field {
            font-size: 14px;

            .value {
                font-size: 14px;
            }
        }
    }
    .virtualTourLink {
        a {
            font-size: 1.015625rem;
        }
    }
    
}