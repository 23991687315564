﻿@import '../mixin.less';
@import '../variable.less';

@media all and (max-width: 1072px) {
    .logo-homepage {
        height: auto;

        img {
            max-width: 250px;
        }
    }

    .link-content {
        .span-3.cell.col-sm-3 {
            float: none;
            width: 100%;
            margin-bottom: 30px;

            img {
                display: block;
                margin: 0 auto 30px;
            }
        }
    }

    .table {
        .span-1,.span-2,.span-3,.span-4,.span-5,.span-6,.span-7,.span-8,.span-9,.span-10,.span-11 {
          width: 100%;
        }
    }
    .placeholder {
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
}

@media all and (max-width: 360px) {
    #before-main {
        .widget-before-main.widget-ad-widget.widget {

            .ads-side-panel {
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                top: 71px;
                text-align: center;
                z-index: 999;
            }
        }

        #container.show-home-page {
            position: absolute;
            left: 0;
            right: 0;
            top: 150px;
            margin: 0 auto;
            z-index: 999;
            h2 {
                font-size:35px ;
            }
        }
    }

    .zone.zone-content {
        .filter-panel.single-selection {
            span {

                label {
                    font-size: 14px;
                }
            }
        }
    }

    .page {
        content-item {
            .orchard-layouts-root {

                img {
                    width: 100%;
                    height: 100%
                }
            }
        }
    }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    #before-main {
        .widget-before-main.widget-html-widget.widget {
            #container.show-home-page {

                h2 {
                    font-size: 20px;
                }
            }
        }

        #main-image.home-page {
            height: 100%;

            img {
                height: 100%;
            }
        }
    }
}
