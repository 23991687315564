﻿@import '../mixin.less';
@import '../variable.less';

@media all and (max-width: 1072px) {
    .title-panel {
        position: relative;

        .home-title,
        .home-title-slider {
            position: static;

            span.home-number {
                text-align: center;
                display: block;
                font-size: 22px;
                padding: 0;
                width: 100%;
                top: 50%;
                transform: translateY(0);
                position: static;
            }

            span.title {
                position: static;
                display: block;
                text-align: center;
                font-size: 18px;
                padding: 15px 0;
                -ms-transform: translateY(0);
                -webkit-transform: translateY(0);
                transform: translateY(0);
                width: 100%
            }
        }

        .home-title-slider {
            margin-top: 60px;

            .home-slider-wrapper {
                height: 100%;
            }
        }
    }
}

@media all and (max-width: 768px) {
    #content-download {

        .span-5.cell,
        .span-2.cell {
            width: 100%;
        }

        .img-responsive {
            margin: 0 auto 10px;
            display: block;
        }
    }

    #wrapper {
        padding-top: 0;
    }

    .span-9.cell {
        a {
            word-break: break-word;
        }
    }

    #tab-content {
        top: 40px;

        .tab-content {
            height: auto;
        }

        ul.nav-tabs {
            margin-left: -45px;

            & > li {
                margin: 0 0 30px 30px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    #carousel-HomePhotos {
        .carousel-inner {
            margin-left: -15px;
            height: auto;
        }
    }
}
