﻿@import "variable.less";
@import "mixin.less";

.titleBuyerGuide {
    .row {
        margin: 0 -15px;

        ul.col-lg-6.tree {
            margin-bottom: 1rem;
        }

        .tree {
            li {
                a {
                    .box-level.box-level-0 {
                        span {
                            color: #fff;
                        }
                    }

                    &:active, &:focus {
                        .box-level.box-level-0 {
                            border: none;

                            span {
                                color: #fff;
                            }
                        }
                    }

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
    }
}
