﻿@import 'variable.less';
//Icon
.icon-font(@icon) {
    content: @icon;
    position: relative;
    right: -10px;
    top: 3px;
    display: inline-block;
    font-family: @fontIcon;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-font-premium() {
    content: "\f054";
    display: inline-block;
    font-family: @fontIcon;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    font-weight: bold;
    color: #ccc;
    font-size: 40px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
}

.icon-social-detail(@icon; @width; @height) {
    background-image: url('@{image_path}@{icon}');
    background-size: contain;
    width: @width;
    height: @height;
    display: block;
    background-position: center;
    background-repeat: no-repeat;
}

//Icon for pagination
.icon-pagination (@icon) {
    &:before {
        content: @icon;
        font-family: @fontIcon;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

//icon for phone and fax
.icon-phone-fax() {
    p.phone {
        position: relative;
        padding-left: 20px;

        &:before {
            position: absolute;
            content: "\f095";
            top: 5px;
            left: 0;
            display: inline-block;
            font-style: normal;
            font-family: @fontIcon;
            font-weight: 900;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }

    p.fax-number {
        position: relative;
        padding-left: 20px;

        &:before {
            position: absolute;
            content: "";
            top: 5px;
            left: 0;
            background: url('@{image_path}fax-icon.jpg') no-repeat left top;
            background-size: 15px 15px;
            width: 15px;
            height: 15px;
        }
    }
}

//name and value
.name-value () {
    .name {
        font-size: 16px;
        color: #3a3a3a;
        position: relative;
    }

    .value {
        font-size: 20px;
        font-weight: bold;
        color: #3a3a3a;
        position: relative;
    }
}

// Premium icon listing
.premium-icon-listing (@posX) {
    padding-right: 50px;

    &:before {
        content: "\f054";
        display: inline-block;
        font-family: @fontIcon;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        font-weight: bold;
        color: #ccc;
        font-size: 40px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(@posX, -50%);
    }
}

//For home number and title
.home-tile-panel() {
    span.home-number {
        background-color: @baseColor;
        font-size: 20px;
        color: @baseLightFontColor;
        padding: 0px 15px;
        line-height: 66px;
        text-align: center;
        display: inline-block;
        z-index: 1;
    }

    span.title {
        background-color: @secondaryColor;
        color: @baseLightFontColor;
        text-align: left;
        font-size: 18pt;
        padding: 21px 21px 21px 10px;
        position: absolute;
        width: 100%;
        z-index: -9;
        top: 50%;
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

//Text Price
.text-price(@text) {
    color: @colorProjection;
    display: inline-block;
    margin-right: 5px;
    position: relative;

    &:after {
        content: @text;
    }
}

.hover-active-tab {
    background: @secondaryColor;
    color: @baseLightFontColor;
    font-size: 16px;
    padding: 5px 10px;
    text-decoration: none;
    display: block;
    border-radius: 0;
}

.hover-active-navigation {
    content: "";
    position: absolute;
    height: 6px;
    background: @baseLightFontColor;
    bottom: -6px;
    left: 0;
    width: 100%;
}
