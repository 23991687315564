﻿@import "variable.less";
@import "mixin.less";

// Start Home Detail
.title-panel {
    position: relative;

    .carousel.slide {
        .home-title-slider {
            display: inline-block;
            line-height: 1;
            background: @baseColor;
            width: 100%;
            overflow: hidden;
            position: absolute;
            top: 450px;
            z-index: 99;
            .home-tile-panel();

            .home-slider-wrapper {
                position: relative;
                height: 66px;
            }
        }

        .home-title {
            display: inline-block;
            line-height: 1;
            background: @baseColor;
            width: 100%;
            overflow: hidden;
            .home-tile-panel();
        }
    }

    .home-title-slider {
        display: inline-block;
        line-height: 1;
        background: @baseColor;
        width: 100%;
        overflow: hidden;
        position: absolute;
        top: 455px;
        z-index: 99;
        .home-tile-panel();

        .home-slider-wrapper {
            position: relative;
            height: 66px;
        }
    }

    .home-title {
        display: inline-block;
        line-height: 1;
        background: @baseColor;
        width: 100%;
        overflow: hidden;
        position: relative;
        z-index: -99;
        .home-tile-panel();
    }

    .featured-home {
        font-size: 18px;
    }
}

.location-wrapper {
    margin: 0 0 20px;
    padding-left: 40px;
    position: relative;

    .direction-wrapper {
        h5 {
            display: inline-block;
            color: #444;
        }
    }

    p {
        line-height: 1.2;
        margin-bottom: 0;
        font-size: 14px;
    }

    &:before {
        content: "";
        position: absolute;
        left: -6px;
        top: -15px;
        background: url('@{image_path}Place_Icon.png') no-repeat left top;
        background-size: 100%;
        width: 40px;
        height: 50px;
    }
}

.image-slide-show {
    padding: 0 60px;
}

.social-panel {
    overflow: hidden;
    padding: 0;
    margin: 20px 0;

    li {
        float: left;
        margin-right: 10px;
        margin-bottom: 5px;

        a {
            display: block;
            height: 32px;
        }

        &:hover {
            opacity: .7;
        }
    }
}

.mid-line {
    color: @baseColor;
    background-color: @baseColor;
    height: 5px;
    border: none;
    margin: 50px 0;
}

.description {
    text-align: left;

    span {
        font-size: 11pt;
        font-family: Calibri,Arial;
        font-style: normal;
        color: @baseLightFontColor2;
    }
}

.text-column {
    padding: 0 30px 0 0;

    .row {
        margin: 0 0 20px;
    }

    .text-field {
        border-bottom: 1px solid #333;
        margin-bottom: 10px;
        font-size: 20px;
        padding-bottom: 10px;
        overflow: hidden;
        line-height: 1.2;

        .name {
            float: left;
        }

        .value {
            float: right;
            font-weight: bold;
            font-size: 24px;
            font-style: italic;
            padding-right: 5px;
            font-family: @fontFamilyTitle;
            color: @colorProjection;
            position: relative;
        }
    }

    .text-field.last {
        border: none;
        overflow: hidden;
        text-align: right;

        span {
            float: left;
        }

        .social-link {
            display: inline-block;
            text-align: right;

            a {
                margin-left: 15px;
                float: left;

                &:hover {
                    opacity: .7;
                }
            }

            .share.facebook-button {
                .icon-social-detail('Social-Media-02.png', 32px - 7px, 32px - 7px );
                text-indent: -9999px;
                padding: 0;
            }

            .share.twitter-button {
                .icon-social-detail('x-logo-black.png', 32px - 7px, 32px - 7px );
                text-indent: -9999px;
                padding: 0;
            }

            .share.google-plus-button {
                .icon-social-detail('Social-Media-04.png', 32px - 7px, 32px - 7px );
                text-indent: -9999px;
                padding: 0;
            }

            .share.pinterest-button {
                .icon-social-detail('Social-Media-05.png', 32px - 7px, 32px - 7px );
                text-indent: -9999px;
                padding: 0;
            }

            .email-button {
                .icon-social-detail('Social-Media-06.png', 32px - 7px, 32px - 7px );
                text-indent: -9999px;
                padding: 0;
            }
        }
    }

    h4 {
        font-family: @fontFamilyTitle;
    }
}

.frist-col {
    font-weight: bold;
    font-style: italic;
    font-family: @fontFamilyTitle;

    p {
        margin-bottom: 5px;
    }

    .social-list-builder {
        margin-left: -5px;
        overflow: hidden;

        a {
            float: left;
            margin-left: 5px;
            text-indent: -9999px;
            color: #666;

            &:hover {
                opacity: .7;
            }
        }

        .FacebookLink {
            .icon-social-detail('facebook.png', 32px - 7px, 32px - 7px );
        }

        .TwitterLink {
            .icon-social-detail('x-logo-black.png', 32px - 7px, 32px - 7px);
        }

        .GoogleLink {
            .icon-social-detail('Google Plus.png', 32px - 7px, 32px - 7px);
        }

        .LinkedinLink {
            .icon-social-detail('linkedin.png', 32px - 7px, 32px - 7px);
        }

        .YoutubeLink {
            .icon-social-detail('YouTube1.png', 32px - 7px, 32px - 7px);
        }

        .PinterestLink {
            .icon-social-detail('pinterest.png', 32px - 7px, 32px - 7px);
        }

        .InstagramLink {
            .icon-social-detail('Instagram.png', 32px - 7px, 32px - 7px);
        }

        .YelpLink {
            .icon-social-detail('Yelp_Yelp_burst_positive_RGB.png', 32px - 7px, 32px - 7px);
        }

        .HouzzLink {
            .icon-social-detail('social_square_green_48px.png', 32px - 7px, 32px - 7px);
        }
    }
}


// End Home Detial

// Start Non-Premium and Premium Detail
.contentMember.product.non-premium {
    .fax-number,
    .address,
    .img-thumbnail-google,
    .col-sm-4.main-image-detail,
    .email,
    .social-panel,
    .button-area {
        display: @displayNone;
    }

    .company-logo {
        display: block;

        p,
        .section_detail,
        .additional-contact {
            display: @displayNone;
        }

        .content-picker-field.content-picker-field-view-our-home {
            margin-top: 30px;

            h4 {
                font-weight: normal;
                margin: 0;
            }

            li {
                a {
                    text-decoration: underline;
                }
            }
        }
    }
}

.contentMember.product.premium {
    .button-area {
        margin-left: -30px;
        overflow: hidden;

        .btn-website {
            display: block
        }

        .btn-website:nth-child(2),
        .btn-map.search-panel {
            display: @displayNone;
        }
    }

    .fax-number,
    .address,
    .email,
    .social-panel,
    .img-thumbnail-google, {
        display: @displayNone;
    }

    .company-logo {
        display: block;

        p,
        .section_detail,
        .additional-contact {
            display: @displayNone;
        }

        .content-picker-field.content-picker-field-view-our-home {
            margin-top: 30px;

            h4 {
                font-weight: normal;
                margin: 0;
            }

            li {
                a {
                    text-decoration: underline;
                }
            }
        }
    }
}

.row.social {
    margin: 0 -15px 20px;
}
//End Non-Premium and Premium Detail
//Start Tab right side for Home Detail page
#tab-content {
    ul.nav-tabs {
        margin-left: -15px;
        padding: 15px 0;

        & > li {
            background: @baseColor;
            margin-left: 15px;

            &:hover {
                & > a {
                    .hover-active-tab;
                }
            }

            & > a {
                color: @baseLightFontColor;
                font-size: 16px;
                padding: 5px 10px;
                display: block;
                -webkit-box-shadow: inset 0 -2px 0 #2196f3;
                box-shadow: inset 0 -2px 0 #2196f3;
                line-height: 1.846;
                display: block;
            }

            & > a.active {
                .hover-active-tab;
                display: block;
            }
        }
    }

    .tab-content {
        height: 450px;
        overflow-x: auto;

        img {
            display: block;
            width: 50%;
            margin: 0 auto 15px;
        }

        .tab-pane {
            & > div {

                & > a {
                    color: #424242;
                    font-size: 16px;
                    position: relative;
                    display: block;

                    &:after {
                        content: "\f054";
                        position: absolute;
                        font-family: @fontIcon;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        top: 50%;
                        right: 0;
                        transform: translate(-100%,-50%);
                    }

                    p {
                        margin: 0;
                    }
                }

                margin: 15px 0;
            }
        }
    }
}
//End Tab right side bar Home Detail

//Start Builder Detail page
.button-back {
    margin: 20px 0;

    .btn-back {
        background: @baseColor;
        color: @baseLightFontColor;
        font-weight: bold;
        font-size: 18px;
        padding: 10px;
        margin-bottom: 20px;

        &:hover {
            color: @baseLightFontColor;
            opacity: .7;
            text-decoration: underline;
        }
    }
}

.main-image-detail {
    margin-bottom: 20px;
}

//Social link for Builder detail page
.FacebookLink {
    .icon-social-detail('facebook.png', 32px, 32px);
}

.TwitterLink {
    .icon-social-detail('x-logo-black.png', 32px, 32px);
}

.GoogleLink {
    .icon-social-detail('Google Plus.png', 32px, 32px);
}

.LinkedinLink {
    .icon-social-detail('linkedin.png', 32px, 32px);
}

.PinterestLink {
    .icon-social-detail('pinterest.png', 32px, 32px);
}

.InstagramLink {
    .icon-social-detail('Instagram.png', 32px, 32px);
}

.YelpLink {
    .icon-social-detail('Yelp_Yelp_burst_positive_RGB.png', 32px, 32px);
}

.YoutubeLink {
    .icon-social-detail('YouTube1.png', 32px, 32px);
}

.HouzzLink {
    .icon-social-detail('social_square_green_48px.png', 32px, 32px);
}


.contentMember {
    margin: 0 20px;

    .row {
        margin-bottom: 0;

        .btn-website.search-panel {
            margin: 5px 0 15px;
        }
    }

    .contentMemberWrap {
        border: 1px solid #333;
        padding: 20px;
        margin-bottom: 30px;

        h3 {
            color: @baseLightFontColor2;
            font-size: 18px;
            font-weight: bold;
            display: block;
            border-bottom: 1px solid @baseLightFontColor2;
            margin: 0 0 10px;
            padding: 0 0 10px;
        }

        .col-sm-4.main-image-detail {

            img {
                width: 100%;
                height: 100%;
            }
        }

        p {
            margin: 0;
            color: @baseLightFontColor2;
        }

        .name {
            font-weight: bold;
        }

        .icon-phone-fax();
    }

    .section_detail {
        margin: 50px 0;

        h4 {
            margin: 0;
        }

        ul {
            li {
                color: @baseLightFontColor2;
                text-decoration: underline;
            }
        }
    }
}

/*For Members Product and Services Detail Page */
.content-member-listing-wrapper {
    .icon-phone-fax();
}

.button-area {
    overflow: hidden;
    margin-left: -30px;
    padding: 0 0 20px;

    & > div {
        float: left;
        margin-left: 30px;
    }

    a.btn {
        color: @baseLightFontColor;
        width: 150px;
        box-shadow: none;
        font-weight: bold;
        background: @baseColor;
        border: 1px solid @baseLightFontColor;

        &:hover {
            opacity: .7;
        }
    }
}

//Virtual Tour Link

.virtualTourLink {
    width: 100%;
    margin-bottom: 15px;

    a {
        text-align: center;
        display: block;
        padding: 5px 0px;
        font-size: 18px;
        color: #fff;

        &:hover {
            text-decoration: none;
            opacity: 0.7;
        }
    }
}
