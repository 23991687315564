﻿@import 'variable.less';
@import 'mixin.less';

html {
    position: relative;
    min-height: 100%;
}

body {
    font-size: 16px;
    color: #7A7778;
    font-family: @fontFamilyBody;
    margin-bottom: 60px;
    overflow-x: hidden;
}

h1,h2 {
    font-family: @fontFamilyTitle;
}

.zone.zone-navigation {
    position: relative;
}

.first-content {
    padding: 15px 0 100px;
}

.header-home {
    max-height: 400px;

    h1,p {
        margin: 0;
    }
}

.widget-signup-widget.widget {
    padding: 20px;

    .popover-area {
        overflow: hidden;

        .status-area {
            text-align: right;
        }

        .icon-system {
            text-align: right;

            ul {
                overflow: hidden;
                list-style: none;
                padding: 0;
                margin: 0;
                display: inline-block;

                li {
                    float: left;
                    margin-left: 15px;
                }
            }
        }
    }
}

.nav-wrapper {
    width: 100%;

    .logo-not-home-page {
        max-height: 200px;
        margin: 20px auto;
        max-width: 250px;

        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin: 0 auto;
            padding: 0 20px;
        }
    }

    .logo-homepage {
        margin: 20px auto;
        max-width: 460px;
        max-height: 260px;

        img {
            &:extend(.nav-wrapper .logo-not-home-page img);
        }
    }
}

#wrapper {
    box-shadow: none;
    border-radius: 0;
      
    .widget-after-main.widget-ad-widget.widget {
        .ads-side-panel {
            text-align: center;

            a {
                display: inline-block;

            }
        }
    }
}

#before-main {
    color: @baseLightFontColor;
    top: -1px;
    position: relative;
    background-size: 100% auto;
    z-index: 3;

    .widget-before-main.widget-ad-widget.widget {
        .ads-side-panel {
            margin: 20px auto;
            text-align: center;
            max-width: 1170px;
            padding: 0 15px;

            a {
                display: block;
            }
        }
    }

    #main-image {
        height: 372px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .widget-before-main.widget-html-widget.widget {
        #container {
            position: absolute;
            left: 0;
            right: 0;
            top: 45%;
            margin: 0 auto;
            z-index: 99;

            img {
                max-width: 75%;
                height: auto;
            }

            h2 {
                color: @baseLightFontColor;
                font-weight: bold;
                font-size: 30px;
            }
        }

        #container.show-home-page {
            position: absolute;
            left: 0;
            right: 0;
            top: 35%;
            margin: 0 auto;
            z-index: 99;

            h1 {
                color: @baseLightFontColor;
                font-weight: bold;
                margin-bottom: 30px;
            }

            h2 {
                color: @baseLightFontColor;
                font-weight: bold;
                font-size: 40px;
            }

        }
    }

    .container {
        position: relative;
        z-index: 4;
    }

    .widget-html-widget {
        img {
            margin: 0;
        }
    }

    h1 {
        color: @baseLightFontColor;
        font-weight: bold;
        margin-bottom: 30px;
    }

    h2 {
        color: @baseLightFontColor;
        font-weight: bold;
        font-size: 40px;
    }

    .down-load-wrapper {
        padding: 40px 0;
        margin-left: 100px;
        position: relative;

        &:before {
            background: url('@{image_path}trapezoid-banner.png') no-repeat left top;
            background-size: 100% auto;
            width: 85%;
            height: 100px;
            content: "";
            position: absolute;
            top: 37px;
            left: 0;
            z-index: 1;
        }

        ul {
            list-style: none;
            position: relative;
            z-index: 2;
            padding: 0;

            li {
                float: left;
                margin-left: 25px;
                color: @baseLightFontColor;
                font-weight: bold;
                font-size: 20px;

                img {
                    margin: 0;
                }
            }

            li.logo-app {
                border-radius: 8px;
                width: 150px;
                height: 150px;
                position: relative;
                top: -38px;

                img {
                    height: 100%;
                    border-radius: 8px;
                }
            }

            li.text {
                margin-top: 15px;
            }

            li.logo-goole {
                width: 135px;
                height: 40px;
                margin-top: 13px;

                a {
                    display: block;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            li.logo-apple {
                width: 135px;
                height: 40px;
                margin-top: 13px;

                a {
                    display: block;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}

.zone-after-content {

    .ads-side-panel {
        margin: 20px auto;
                
        a {
            display: block;
        }
    }
}

#main-image-home-page {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
}

#main-image-all-page {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 372px;
}


.zone-after-content {
    margin: 0 0 30px;
}
    
.page-header {
    display: @displayNone;
}

.home.content-item {
    .photo-inner {
        margin-bottom: 30px;
    }

    .page-header {
        display: block;
        border-bottom: 1px solid #3a3a3a;
        margin: 0 0 10px;
        padding-bottom: 10px;

        h1 {
            font-size: 22px;
            font-weight: bold;
            margin: 0;
        }
    }

    .location-wrapper {
        padding-left: 40px;
        position: relative;
        margin-bottom: 30px;

        &:before {
            content: "\e062";
            top: 25px;
            position: absolute;
            left: -10px;
            font-size: 2.5em;
            color: #808080;
            display: inline-block;
            font-family: 'Glyphicons Halflings';
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }

    p {
        margin-bottom: 0;
    }
}

.page.content-item {
    .link-content {
        font-size: 16px;
        margin-top: 50px;

        .cell {
            padding-left: 15px;
            padding-right: 15px;
        }

        h3 {
            font-size: 20px;
            font-weight: bold;
        }

        a {
            background: #73CDD6;
            color: @baseLightFontColor;
            padding: 5px 25px;
            margin-top: 10px;
            display: inline-block;
            overflow: hidden;
            border-radius: 5px;
            border: 1px solid @baseLightFontColor;

            &:hover {
                background: @baseLightFontColor;
                color: #73CDD6;
                text-decoration: none;
                border: 1px solid #73CDD6;
            }
        }
    }
}

.row.link-content {
    margin: 0;
}
    
.home-wrapper {
    background: #f3f3f3;
    padding: 100px 50px;

    h2 {
        font-size: 22px;
        font-weight: bold;
        color: #3a3a3a;
        margin: 0 0 30px;
        text-align: center;
    }

    p {
        margin-bottom: 30px;
        text-align: center;
        color: #3a3a3a;
    }

    .button-wrapper {
        padding-top: 30px;

        ul {
            list-style: none;
            padding: 0;

            li {
                p {
                    a {
                        color: @baseLightFontColor;
                        position: relative;
                        padding: 20px 20px 19px 0;
                        text-align: center;
                        background: #a9deea;
                        height: 55px;

                        &:before {
                            content: "\e021";
                            height: 55px;
                            position: relative;
                            margin-right: 10px;
                            top: 10px;
                            font-size: 30px;
                            display: inline-block;
                            font-family: 'Glyphicons Halflings';
                            font-style: normal;
                            font-weight: 400;
                            line-height: 1;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            background: #73ccd6;
                            padding: 14px 14px 13px;
                        }

                        &:hover {
                            text-decoration: none;
                            background: #73ccd6;

                            &:before {
                            }
                        }
                    }

                    a.map-view {
                        &:before {
                            content: "\e062";
                        }
                    }
                }
            }
        }
    }
}

.table {
    margin-bottom: 0;

    & > .row {
        margin: 0;
    }
}
    
.img-center {

    img {
        margin: 20px auto;
    }
}

.img-responsive {
    max-width: 100%;
}

#footer {
    color: @baseLightFontColor;
    margin-top: 40px;
    background: @baseColor;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;

    p {
        margin-bottom: 0;
        font-size: 14px;

        a {
            color: @baseLightFontColor;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.placeholder {
    width: 250px;
    display: inline-block;
    margin-top: 20px;

    img {
        width: 100%;
        min-height: 100%;
    }
}

#about-page {
    padding-top: 20px;

    h3 {
        color: @baseColor;
        font-weight: bold;
        margin: 0 0 15px;
        font-size: 24px;
        text-align: center;
    }

    h4 {
        font-size: 20px;
        font-weight: bold;
    }

    .underline {
        text-decoration: underline;
    }

    .logo-app {

        .logo {
            width: 100%;
            height: 259px;
            margin-bottom: 15px;
            border-radius: 10%;
        }

        img {
            width: 100%;
            height: 100%;
        }

        ul {
            list-style: none;
            padding: 0;
            margin-left: -20px;

            li {
                float: left;
                margin-left: 13px;
                width: 45%;
            }
        }
    }
}

form[role=form] {
    display: none;
}


.photo-wrapper.company-logo {
    .photo-inner {
        width: 100%;
        margin-bottom: 50px;
        display: block;
    }
}

.events.content-item {
    .tab-content {
        margin: 30px 0;
    }
    .page-header {
        display: block;
        padding-bottom: 10.5px;
        margin: 0;
        border-bottom: none;
        h1 {
            font-size: 30px;
            font-weight: bold;
        }
    }
}

.link-field.link-field-alternative-link {
    margin-bottom: 2em;
    .name {
        font-size: 14px;
        margin-right: 5px;
        padding-left: 40px;
        position: relative;
        font-weight: normal;
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            content: "\e144";
            font-family: 'Glyphicons Halflings';
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            color: #468fcc;
            font-size: 20px;
        }
    }
    .value {
        a {
            font-size: 14px;
            text-decoration: underline;
            font-weight: bold;
            &:hover {
                text-decoration: none;
            }
        }
    }
}

.text-body {
    margin-bottom: 30px;
    overflow: hidden;

    div.txt-content {
        padding-left: 10px;
        overflow: hidden;
        line-height: 1.4;
        margin: 0 0 0 30px;
    }
    p.glyphicon:empty {
        background: #468fcc;
        padding: 5px;
        color: @baseLightFontColor;
        font-weight: bold;
        display: block;
        float: left;
        border-radius: 3px;
        width: auto;
        font-size: 20px;
    }
}

.event-datail-wrapper {
    textarea{
        margin-bottom: 15px;
    }
}

.PreviewImg {
    max-height: 100%;
    max-width: 100%;
}

.logoThumbnail {
    max-height: 100%;
    max-width: 100%;
}

#event-editor-tab-1 {
    .image-icon-wrapper {
        position: absolute;
        right: 0;
        top: 0;
        margin: 0;
        width: 200px;
        .image-icon {
            border: 3px solid @baseColor;
            padding: 0;
        }
    }
}
// move this code to detail less file
#sponsor {
   .sponsor-title {
        border-bottom: 1px solid @baseLightFontColor2;
        margin-bottom: 10px;
        padding: 0 20px 10px;
        h2 {
            margin-top: 0;
            font-size: 35px;
        }
        &:last-child {
            margin-bottom: 20px;
            padding-bottom: 20px;
            border: none;
        }
        p {
            font-size: 10pt;
            margin-bottom: 0;
        }
    }
}
// move this code to detail less file
ul.list-group.builder-list {
    li {
        img {
            width: 50%;
            max-height: 100%;
        }
        a {
            color: #666;
            &:hover {
                color: #666;
                text-decoration: underline;
            }
            &:active {
                color: #666;
            }
        } 
    }
}

/*Products/Services Detail Page*/
#content-download {
    .img-responsive {
        margin: 0;
        display: inline; 
    }
}

#about-page {
    ul {
        padding: 0;
        list-style: none;
    }
}

.p1 {
    a {
        word-break: break-word;
    }
} 

.widget-before-main.widget-rotate-ad-widget.widget {
    .ads-top-panel {
    max-width: 1170px;
    margin: 15px auto;
    padding: 0 15px;

        img {
            width: 100%;
            height: auto;
        }
    }
}

.widget-before-content.widget-rotate-ad-widget.widget {
    .ads-top-panel {
        max-width: 1170px;
        margin: 15px auto;
        padding: 0 15px;

        img {
            width: 100%;
            height: auto;
        }
    }
}

.widget-after-content.widget-rotate-ad-widget.widget {
    .ads-top-panel {
    max-width: 1170px;
    margin: 15px auto;
    padding: 0 15px;
        img {
            width: 100%;
            height: auto;
        }
    }
}

#google-map-address {
    display: none;
}

//Home Sold

.home-sold-wrap {
    position: relative;

    .home-sold {
        position: absolute;
        right: 10px;
        bottom: 10px;
        font-size: 30px;
        padding: 0 15px;
        margin: 0;
    }
}

#home-list {
    .home-sold {
        position: absolute;
        right: 30px;
        bottom: 10px;
        font-size: 30px;
        padding: 0 15px;
        margin: 0;
    }

    .featured-home {
        right: 21px;
        font-size: 16px;
    }
}

.home-sold-detail {
    position: absolute;
    right: 10px;
    bottom: 86px;
    font-size: 30px;
    padding: 0 15px;
    margin: 0;
}

.home-sold-detail-imageSlide {
    position: absolute;
    right: 10px;
    bottom: 186px;
    font-size: 30px;
    padding: 0 15px;
    margin: 0;
    z-index: 99;
}