﻿@import 'variable.less';
@import 'mixin.less';

.list-event-inner {
    border: none;
}
        
.list-event-wrapper {
    .list-event-inner {
        border: none;

        .head-title {
            ul {
                list-style: none;
                padding: 0;
                margin-left: -5px;

                li {
                    float: left;
                    margin: 0 5px;
                    position: relative;

                    &:before {
                        content:"/";
                        color: @baseColor;
                        position: relative;
                        left: -3px;
                    }

                    a {
                        color: @baseColor;
                    }

                    &:first-child {
                        &:before {
                            display: @displayNone;
                        }
                    }
                }
            }
        }
        .fc-toolbar {
            position: relative;

            .fc-right {
                .fc-button-group {
                    display: @displayNone;
                }
            }

            .fc-left {
                button.fc-today-button {
                    display: @displayNone;
                }
            }

        }
    }
}

/*This code for Header calendar page*/
.ui-widget-header {
    & > div.fc-row.ui-widget-header {
        & > table {
            border: @baseColor;

            & > th {
                background: @baseColor;
                color: @baseLightFontColor;
                border: @baseColor;
            }

            & > td {
                border: @baseColor;

                .ui-widget-content {
                    border: @baseColor;
                }
            }
        }
    }
}
    
/*This code for Content calendar page*/
.fc-day-number {
    text-align: left!important;
    color: @baseColor!important;
    font-size: 24px;
    padding: 0 3px!important;
    line-height: 1;

    &:before {
        background: none;
    }
}
    
.fc-content {
    color: @baseLightFontColor;
}

/*This for Event List*/
.list-event {
    padding: 10px 0;

    ul.list {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            border: 5px solid @baseColor;
            padding: 5px;
            background: #FFAA9A;
            margin-bottom: 10px;

        	div.list-event-inner {
        		color: #232028;
        		border: none;
        		padding-left: 0;

        	    .image-icon-wrapper {
                    .image-icon {
                        border: 3px solid @baseColor;
                    }
        	    }

        		h2.tittle {
        			font-weight: bold;
        			font-size: 20px;
        			margin: 0 0 10px;

                    a {
                        color: #232028;
                    }
                }

        		dl.dl-list-event {
        			overflow: hidden;
        			margin: 0 0 5px 0;

        			dt {
        				float: left;
        				width: 160px;
        			}

        			dd {
        				margin-left: 160px;
        				overflow: hidden;

            			a {
            				color: #232028;
            				text-decoration: underline;

                            &:hover {
                                text-decoration: none;
                            }
            			}
                    }
                }
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

/*Pagenation for event list*/
.pagination-wrapper-list {
    overflow: hidden;
    margin-top: 10px;

    #pagination {
        float: right;

        ul.pagination {
            margin: 0;
            overflow: hidden;
            margin-left: -8px;

            li {
                float: left;
                margin-left: 8px;

                a {
                    display: block;
                    border: none;
                    padding: 0 10px;
                    background: @baseColor;
                    color: @baseLightFontColor;

                    &:hover {
                        color: @baseLightFontColor;
                        background: #FF917D;
                    }

                    i.fa.fa-angle-right {
                        .icon-pagination ('\e080')
                    }

                    i.fa.fa-angle-double-right {

                        &:before {
                            content: "Last"
                        }
                    }

                    i.fa.fa-angle-left {
                        .icon-pagination ('\e079')
                    }

                    i.fa.fa-angle-double-left {

                        &:before {
                            content: "First"
                        }
                    }
                }

                a#pager-current {
                    color: @baseLightFontColor;
                    background: #FF917D;
                }
            }
        }
    }
}
.date-time-wrapper {
    background: @baseColor;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 80px;
    display: table;
    width: 440px;
    color: @baseLightFontColor;

    p {
        margin-bottom: 0;
    }

    .icon-date-time {
        font-size: 40px;
        color: @baseLightFontColor;
        line-height: 0;
        display: table-cell;
        width: 40px;
        vertical-align: middle;
    }

    .date-time-inner {
        overflow: hidden;
        margin-left: 50px;
        display: table-cell;
        padding-left: 15px;
    }

    .name {
        font-size: 14px;
        font-weight: bold;
    }

    .value {
        font-size: 14px;
    }
}
