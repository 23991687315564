﻿@import '../mixin.less';
@import '../variable.less';

@media all and (max-width: 768px) {
    .widget-navigation.widget-menu-widget.widget {
        position: relative;
    }

    .sponsor-slide {
       .carousel-inner {

           & > .active.left,
           & > .prev,
           & > .active.right,
           & > .next {
               left: 50%;
           }

           & > .active,
           & > .left,
           & > .prev.right {
              left: 0;
           }

           .cloneditem-1 {
              display: block;
           }
        }
    }
}

@media all and (min-width: 768px) and (transform-3d),
all and (min-width: 768px) and (-webkit-transform-3d) {
   .sponsor-slide {
       .carousel-inner {

           & > .item.active.right,
           & > .item.next,
           & > .item.active.left {
                -webkit-transform: translate3d(50%, 0, 0);
                transform: translate3d(50%, 0, 0);
                left: 0;
            }

           & > .item.active.left,
           & > .item.prev  {
              -webkit-transform: translate3d(-50%, 0, 0);
              transform: translate3d(-50%, 0, 0);
              left: 0;
           }

            & > .item.left,
            & > .item.prev.right,
            & > .item.active   {
              -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
              left: 0;
           }
        }
    }
}

@media all and (max-width: 992px) {

    .carousel {
       .item {
           .col-xs-12 {
               &:nth-last-child(-n+2) {
                  display: none;
               }
            }
        }
    }

    .mid-line {
        margin: 15px 0;
    }

    #tab-content {
        .tab-content {
            height: auto;
        }
    }

   .sponsor-slide .carousel-inner {
       & > .active.left,
       & > .prev,
       & > .active.right,
       & > .next {
          left: -16.6%;
       }

       & > .left,
       & > .prev.right,
       & > .active {
          left: 0;
       }

       .cloneditem-2,
       .cloneditem-3  {
          display: block;
       }
    }
}

@media all and (min-width: 992px) and (transform-3d),
all and (min-width: 992px) and (-webkit-transform-3d) {
   .sponsor-slide .carousel-inner {

       & > .item.next,
       & > .item.active.right {
          -webkit-transform: translate3d(16.6%, 0, 0);
          transform: translate3d(16.6%, 0, 0);
          left: 0;
       }

       & > .item.active.left,
       & > .item.prev {
          -webkit-transform: translate3d(-16.6%, 0, 0);
          transform: translate3d(-16.6%, 0, 0);
          left: 0;
       }

       & > .item.left,
       & > .item.prev.right,
       & > .item.active {
          -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
          left: 0;
       }
   }
}

@media all and (max-width: 360px) {
    #before-main {
        .widget-before-main.widget-ad-widget.widget {

            .ads-side-panel {
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                top: 71px;
                text-align: center;
                z-index: 999;
            }
        }

        #container.show-home-page {
            position: absolute;
            left: 0;
            right: 0;
            top: 150px;
            margin: 0 auto;
            z-index: 999;
            h2 {
                font-size:35px ;
            }
        }
    }

    .zone.zone-content {
        .filter-panel.single-selection {
            span {

                label {
                    font-size: 14px;
                }
            }
        }
    }
}