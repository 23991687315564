﻿@import "variable.less";
@import "mixin.less";

//Button Hamberger
.navbar.navbar-expand-lg {
    background: @baseColor;
    min-height: 70px;
    width: 100%;
}

.navbar-light {
    .navbar-toggler {
        color: rgba(0,0,0,0.5);
        border-color: rgba(0,0,0,0);
        position: absolute;
        right: 15px;
        top: 20px;
    }
}

.navbar {
    padding: 0;

    .navbar-nav {
        display: table;
        float: none;
        vertical-align: middle;
        width: 1100px;
        margin: 0 auto;

        & > li {
            display: table-cell;
            float: none;
            margin: 0 15px;

            & > a {
                font-size: 20px;
                color: @baseLightFontColor;
                font-weight: normal;
                text-decoration: none;
                opacity: 1;
                padding: 0.5rem;
                position: relative;
                display: block;
                margin: 0 1rem;
                &:active {
                    background: none;
                }

                &:hover {
                    background: none;
                    color: @baseLightFontColor;

                    &:after {
                        .hover-active-navigation;
                    }
                }
            }
        }

        & > .active {

            & > a {
                background: none;
                position: relative;
                color: @baseLightFontColor;
                padding: 0.5rem;

                &:after {
                    .hover-active-navigation;
                }
            }
        }
    }

    .navbar-collapse {
        text-align: center;
        padding: 0;

        .raw {
            img {
                position: relative;
                top: -2px;
                right: -4px;
            }
        }

        #social-icon {
            position: absolute;
            right: -80px;
            bottom: 0;
            z-index: 999;

            a {
                &:hover {
                    opacity: .7;
                }
            }
        }
    }
}

.widget-navigation.widget-menu-widget.widget {
    width: 100%;
    margin: 0 auto;
}

.login {
    display: none; 
}